<template v-if="position && position.type === 2">
  <Spinner v-if="showSpinner" isSmall :spinnerStyle="'width: 32px; height: 32px'"></Spinner>
  <div v-else v-tooltip.top="RequestPositionStates.find(state => state.num === position.state)?.label[$i18n.locale]"
       @click="toggleRequestStateOverlay($event, position.id)"
       class="request-state-wrapper"
       @contextmenu="onRequestPositionStateClick($event, position)">
    <div class="request-state request-state--big" :class="RequestPositionStates.find(pos => pos.num === position.state)?.classData">
      <span v-if="position.request_state_history?.length">{{ position.request_state_history.length }}</span>
    </div>
    <OverlayPanel :ref="'rph-' + (isModal ? 'modal-' : '') + position.id">
      <div v-if="position.request_state_history?.length">
        <div v-for="(data, index) of [...position.request_state_history].sort((a, b) => b.id - a.id)" :key="index">
          <div class="p-d-flex p-ai-center p-jc-between">
            <div class="p-d-flex p-ai-center">
              <div class="state-badge" :class="RequestPositionStates.find(state => state.num === data.new)?.classData || ''">{{ RequestPositionStates.find(state => state.num === data.new)?.label[$i18n.locale] }}</div>
            </div>
            <div class="p-ml-3 p-mr-3">
              <span v-if="data.created_at">{{ formatDate(data.created_at) }},</span> <span v-if="data.creator && data.creator.first_name">{{ data.creator.first_name }}</span> <span v-if="data.creator && data.creator.last_name">{{ data.creator.last_name }}</span>
            </div>
          </div>
          <br v-if="index === position.request_state_history.length - 1">
          <div class="p-d-flex p-ai-center p-jc-between" v-if="index === position.request_state_history.length - 1">
            <div class="p-d-flex p-ai-center">
              <div class="state-badge"
                   :class="RequestPositionStates.find(state => state.num === position.request_state_history[0].old)?.classData || ''">
                {{ RequestPositionStates.find(state => state.num === position.request_state_history[0].old)?.label[$i18n.locale] }}
              </div>
            </div>
            <div class="p-ml-3 p-mr-3">
              <span v-if="position.created_at">{{ formatDate(position.created_at) }},</span> <span v-if="position.creator && position.creator.first_name">{{ position.creator.first_name }}</span> <span v-if="position.creator && position.creator.last_name">{{ position.creator.last_name }}</span>
            </div>
          </div>
          <br v-if="index !== position.request_state_history.length - 1">
        </div>
      </div>
      <div v-else>{{ $t('No data available' )}}</div>
    </OverlayPanel>
    <RequestPositionContextMenu
        @leaf-click="onChangeRequestState($event, position.state, position)"
        ref="requestStatesMenuAccordion"
        :notOrderedStates="computedNotOrderedRequestPositionStates"
        :orderedStates="computedOrderedRequestPositionStates"/>
<!--        :model="computedRequestPositionStates"/>-->
  </div>
  <!--  <div v-else class="request-state request-state&#45;&#45;big"></div>-->
</template>
<script>
import RequestPositionStates from '@/translations/states/RequestPositionStates'
// import RequestPositionContextMenu from '@/components/RequestPositionContextMenu'
import RequestPositionContextMenu from "@/pages/orders/components/RequestPositionContextMenu";
import httpClient from "@/services/http.services";
// import httpMixins from "@/mixins/httpMixins";
import constants from '@/constants'
import settings from "@/settings";
import formatMixins from "@/mixins/formatMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  components: { RequestPositionContextMenu },
  mixins: [ formatMixins, showErrorsMixins ],
  emits: ['update-items', 'update-request-state-history'],
  name: 'RequestPositionStateButton',
  props: {
    orderIsArchived: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    orderId: Number,
    position: Object,
    isModal: {
      type: Boolean,
      default: false
    },
    // disabled: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      showSpinner: false,
      // positionToUpdate: null,
      RequestPositionStates,
      constants
    }
  },
  methods: {
    // onRequestPositionStateClick(event, position) {
    // onRequestPositionStateClick(event) {
    //   if (this.isDisabled ||
    //       position.status === 0 ||
    //       (this.$store.state.user.role !== constants.userRoles.superAdmin && this.$store.state.user.role !== constants.userRoles.admin)
    //       || (this.orderIsArchived && (this.$store.state.user.role !== constants.userRoles.superAdmin && this.$store.state.user.role !== constants.userRoles.admin))) {
    //     event.preventDefault()
    //     return false
    //   }
    //   this.$refs.requestStatesMenuAccordion.show(event);
    // },
    onRequestPositionStateClick(event, position) {
      const userIsAdmin = this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin
      if (this.isDisabled ||
          (position.status === 0 && !userIsAdmin) ||
          (this.orderIsArchived && !userIsAdmin)) {
        event.preventDefault()
        return false
      }
      this.$refs.requestStatesMenuAccordion.show(event);
    },
    async onChangeRequestState(newState, oldState, position) {
      if (position.status === 0 &&
          (this.$store.state.user.role !== constants.userRoles.superAdmin &&
           this.$store.state.user.role !== constants.userRoles.admin &&
           this.$store.state.user.role !== constants.userRoles.accountant)) {
          // newState === oldState) {
        return false
      }

      // if (newState < 4) {
      //   const { success } = await this.checkPositionState(position, newState)
      //   if (!success) {
      //     return
      //   }
      // }

      // console.log('after checkPositionState')

      if (newState === oldState) {
        return
      }

      position.state = newState
      position.isUpdated = !position.isNew
      // if (position.isNew) {
      //   console.log(1)
      //   if (position.id) {
      //     console.log(2)
      //     position.isUpdated = true
      //   }
      // } else {
      // if (!position.isNew && !this.isModal) {

      if (!this.isModal) {
        this.changeRequestState(newState, oldState, position)
      }
      // else if (position.id && newState > 3) {
      //   this.checkRequestOrderedQty(newState, oldState, position)
      // }
    },
    async checkPositionState(position, newState) {
      this.showSpinner = true
      const obj = { positionId: position.id, state: newState}
      try {
        const { status, data } = await httpClient.post('order-position/check-state', obj)
        if (status === 200 && data?.success) {
          console.log(data)
          const fixedQty = data.fixed_qty ? data.fixed_qty : 0
          const reducedQty = data.reduced_qty ? data.reduced_qty : 0
          const qty = data.qty ? data.qty : 0
          const state = data.state

          position.fixed_qty = fixedQty
          position.reduced_qty = reducedQty
          position.qty = qty
          position.state = state


          if (fixedQty || reducedQty) {
            this.$toast.add({severity:'warn', detail: 'Request already ordered', life: settings.toastLife});

            return { success: false }
          } else {
            return { success: true }
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.showSpinner = false
      }
    },
    async changeRequestState(newState, oldState, position) {
      this.showSpinner = true
      const obj = { positionId: position.id, qty: position.qty, state: newState}
      try {
        const { status, data } = await httpClient.post('order-position/change-state', obj)
        if (status === 200 && data?.success) {
          // console.log(data)
          // if (!position.request_state_history) {
          //   position.request_state_history = []
          // }
          // position.request_state_history.push(data)

          if (data.permission_denied) {
            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Permission denied', life: settings.toastLife})
            position.state = oldState
            return false
          }

          if (data.request_state_history) {
            position.request_state_history = data.request_state_history
          }

          position.ordered_qty = data.ordered_qty ? +data.ordered_qty : 0

          this.updatedItem = null
          this.$emit('update-request-state-history', position.id, position.request_state_history, newState)
        } else if (data?.error) {
          position.state = oldState
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        } else {
          position.state = oldState
          this.showUnknownError()
        }
      } catch(err) {
        position.state = oldState
        this.showError(err)
        // this.$toast.add({severity:'error', summary: this.$t('Error'), detail: `${err.message}`, life: settings.toastLife})
        // console.log(err)
      } finally {
        this.showSpinner = false
      }
    },
    // async checkRequestOrderedQty(newState, oldState, position) {
    //   if (!position) return
    //   this.showSpinner = true
    //   const obj = { positionId: position.id , qty: position.qty, state: position.state }
    //   try {
    //     const { status, data } = await httpClient.post('order-position/check-ordered-qty', obj)
    //     if (status === 200 && data?.success) {
    //       return
    //     } else if (data?.min_qty) {
    //       const minQty = +data.min_qty
    //       position.state = oldState
    //       position.min_qty = minQty
    //       this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Minimum quantity is ' + minQty, life: settings.toastLife})
    //     } else {
    //       position.state = oldState
    //       this.showUnknownError()
    //     }
    //   } catch(err) {
    //     position.state = oldState
    //     this.showError(err)
    //   } finally {
    //     this.showSpinner = false
    //   }
    // },
    toggleRequestStateOverlay(event, id) {
      if (!event || !id) return false
      this.$refs['rph-' + (this.isModal ? 'modal-' : '') + id.toString()].toggle(event);
    },
  },
  computed: {
    computedNotOrderedRequestPositionStates() {
      if ((this.$store.state.user.role === this.constants.userRoles.superAdmin ||
          this.$store.state.user.role === this.constants.userRoles.admin ||
          this.$store.state.user.role === this.constants.userRoles.accountant))
          // && (!+this.position.fixed_qty && !+this.position.reduced_qty))
      {
        return RequestPositionStates.filter(item => item.num < 4)
      } else if (this.$store.state.user.role === this.constants.userRoles.moderator) {
        if ((!+this.position.fixed_qty || this.position.isNew || this.position.state === 1 || this.position.state === 3)
            && !+this.position.reduced_qty) {
          return RequestPositionStates.filter(item => item.num < 4)
        } else {
          return null
        }
      } else {
        return null
      }
    },
    computedOrderedRequestPositionStates() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin ||
          this.$store.state.user.role === this.constants.userRoles.admin ||
          this.$store.state.user.role === this.constants.userRoles.accountant ||
          this.$store.state.user.role === this.constants.userRoles.moderator) {
        return RequestPositionStates.filter(item => item.num >= 4)
      } else {
        return null
      }
    }
  }
}
</script>
<style lang="scss">
.request-state-wrapper {
  display: block;
  //padding: 6px;
  cursor: pointer;
  //border-radius: 2px;
  //min-width: 23px;
  &:hover {
    opacity: 0.7;
  }
}
.request-state {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 2px;
  height: 24px;
  width: 24px;
  border-radius: 2px;
  font-size: 0.9em;
}
.request-state--big {
  height: 32px;
  width: 32px;
}
.request-state--new {
  background-color: #C8E6C9;
}
.request-state--сanceled {
  background-color: #FFCDD2;
}
.request-state--to-order {
  background-color: #FEEDAF;
}
.request-state--ordered {
  //background-color: #a2d1ff;
  background-color: #a2d1ff;
}
.request-state--waiting-delivery {
  background-color: #ffbe8f;
}
.request-state--need-to-deliver {
  background-color: #9aa5f5;
}
.request-state--delivered {
  //background-color: #c9eefb;
  background-color: #707afe;
}
</style>